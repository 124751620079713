import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/cbc-logo.png";

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-white shadow-sm">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src={logo} width="60" height="60" className="d-inline-block align-top" alt="Logo" />
                </Link>
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    onClick={toggleMenu}
                    aria-expanded={isOpen}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={() => setIsOpen(false)}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about" onClick={() => setIsOpen(false)}>About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/projects" onClick={() => setIsOpen(false)}>Projects</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/resume" onClick={() => setIsOpen(false)}>Resume</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link contact-link" to="/contact" onClick={() => setIsOpen(false)}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}