import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faLightbulb, faRocket } from '@fortawesome/free-solid-svg-icons';
import Laptop from "../assets/images/laptop.png";

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const webhookURL = 'https://hook.us1.make.com/x6ggvwhapp7qqbwv7qumlldvsn1hxdm5'; 
    try {
      const response = await fetch(webhookURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormSubmitted(true);
        alert('Your message has been sent!');
      } else {
        alert('There was a problem submitting the form. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <div className="container my-5">
      {/* Consulting Section */}
      <section className="consulting-hero text-center py-5">
        <h1 className="display-5 mb-4">Let's Work Together</h1>
        <p className="lead mb-5 col-lg-8 mx-auto">
          With expertise in web development and digital solutions, I help businesses 
          create impactful online experiences that drive results.
        </p>
        <img src={Laptop} alt="Development workspace" className="img-fluid rounded shadow-lg mb-5" style={{maxHeight: "400px"}} />
      </section>

      {/* Services Overview */}
      <section className="services-overview py-5 bg-light rounded-3">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-4">
              <div className="text-center p-4">
                <FontAwesomeIcon icon={faCode} size="2x" className="text-secondary mb-3" />
                <h4>Development</h4>
                <p>Custom web solutions tailored to your specific needs and goals.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center p-4">
                <FontAwesomeIcon icon={faLightbulb} size="2x" className="text-secondary mb-3" />
                <h4>Consulting</h4>
                <p>Strategic guidance to help you make informed technology decisions.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center p-4">
                <FontAwesomeIcon icon={faRocket} size="2x" className="text-secondary mb-3" />
                <h4>Growth</h4>
                <p>Scalable solutions that grow with your business needs.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form */}
      <section className="contact-form py-5">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-center mb-4">Get In Touch</h2>
            <p className="text-center mb-5">
              Have a project in mind? Let's discuss how we can help your business grow.
            </p>
            <form onSubmit={handleSubmit} className="contact-form shadow-sm rounded p-4 bg-white">
              <div className="mb-4">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="form-label">Message</label>
                <textarea
                  className="form-control form-control-lg"
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-lg px-5">Send Message</button>
              </div>
            </form>

            {formSubmitted && (
              <div className="alert alert-success mt-4 text-center" role="alert">
                Thank you for your message! I'll get back to you soon.
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;