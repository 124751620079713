import React from 'react';
import backgroundImage from "../assets/images/chitech_abstract.png";
import unierosScreen from "../assets/images/UnierosScreenshot.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faBootstrap, faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';

import Contact from './Contact';
import { Link } from 'react-router-dom';

const Home = () => {
  const jumbotronStyle = {
    backgroundImage: `linear-gradient(rgba(30, 41, 59, 0.95), rgba(51, 65, 85, 0.9)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
    padding: "0 20px",
    position: "relative",
    transition: "all 0.3s ease",
  };

  return (
    <div className="container-fluid p-0">
      {/* Hero Section */}
      <div className="jumbotron jumbotron-fluid" style={jumbotronStyle}>
        <div className="container">
          <h1 className="display-4">Christine Brown Clayton <br/> Web Developer</h1>
          <p className="lead">Building creative and impactful web solutions.</p>
          <a href="#portfolio" className="btn btn-primary">Explore My Work</a>
        </div>
      </div>

      {/* Portfolio Section - Moved up */}
      <section className="portfolio py-5" id="portfolio">
        <div className="container">
          <div className="row align-items-center g-4">
            <div className="col-md-6">
              <div className="portfolio-content pe-md-4">
                <h2 className="mb-4">Featured Project</h2>
                <h6 className="mb-3">Unieros.com</h6>
                <p>
                  Unieros is a social platform I founded to connect people from diverse cultures through in-person events. It's more than just a website – it's a community. 
                </p>
                <p className="mb-4">
                  As the lead developer, I created a platform that not only facilitates connections but also integrates generative AI for dynamic features such as image generation and language translation.
                </p>
                <p className="lead mb-3">Tech Stack:</p>
                <p className="mb-4">
                  <FontAwesomeIcon icon={faBootstrap} title="Bootstrap" size="2x" className="me-3" />
                  <FontAwesomeIcon icon={faReact} title="React" size="2x" className="me-3" />
                  <FontAwesomeIcon icon={faWordpress} title="WordPress" size="2x" />
                </p>
                <Link to="/projects" className="btn btn-primary">View More Projects</Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="portfolio-image">
                <img className="img-fluid rounded shadow-sm" src={unierosScreen} alt="Unieros project screenshot" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section - Moved after portfolio */}
      <section className="about container my-5 py-5">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img className="img-fluid headshot" src={require("../assets/images/headshot.jpeg")} alt="Christine headshot" />
          </div>
          <div className="col-md-6">
            <h2>About Me</h2>
            <p className="lead">
              Hello! I'm Christine, a passionate web developer with a focus on building platforms that bring people together.
            </p>
            <p>
              My journey began with a curiosity for technology, leading me to create my first website using Drupal CMS. Since then, I've dedicated myself to developing user-friendly web solutions that bridge cultures and enhance connections.
            </p>
            <Link to="/about" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
      </section>

      {/* Services Section - Modified */}
      <section className="pricing py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-5">Services & Pricing</h2>
          <div className="row justify-content-center">
            <div className="col-lg-4 mb-4">
              <div className="card h-100 shadow-sm hover-card">
                <div className="card-body">
                  <h5 className="card-title text-center">Basic Website</h5>
                  <h6 className="card-price text-center">$999<span className="period">/project</span></h6>
                  <hr />
                  <ul className="fa-ul">
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>5 Pages</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Mobile Responsive</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Basic SEO</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Contact Form</li>
                  </ul>
                  <div className="d-grid">
                    <Link to="/contact" className="btn btn-primary">Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card h-100 shadow hover-card featured">
                <div className="card-body">
                  <h5 className="card-title text-center">Web Support</h5>
                  <h6 className="card-price text-center">$499<span className="period">/month</span></h6>
                  <hr />
                  <ul className="fa-ul">
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Regular Updates</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Security Monitoring</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Performance Optimization</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>24/7 Support</li>
                  </ul>
                  <div className="d-grid">
                    <Link to="/contact" className="btn btn-primary">Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card h-100 shadow-sm hover-card">
                <div className="card-body">
                  <h5 className="card-title text-center">Custom Solution</h5>
                  <div className="price-wrapper text-center mb-3">
                    <h6 className="card-price mb-2">Custom<span className="period">/project</span></h6>
                    <div className="hourly-rate">
                      <FontAwesomeIcon icon={faClock} className="me-2" />
                      <span>$50<span className="period">/hour</span></span>
                    </div>
                  </div>
                  <hr />
                  <ul className="fa-ul">
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Custom Features</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>API Integration</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Full Stack Development</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Maintenance Plan</li>
                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} /></span>Flexible Billing Options</li>
                  </ul>
                  <div className="d-grid">
                    <Link to="https://buy.stripe.com/3cs17g5Amcpe5B6fZ6" target="_blank" className="btn btn-primary">Purchase</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;